<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>

            <b-tab
              v-for="language in languages"
              :key="language.code"
            >
              <template #title>
                <b-img
                  :src="require('@/assets/images/flags/' + language.code + '.svg')"
                  height="16"
                  width="16"
                  class="mr-1"
                />
                <span class="d-none d-sm-inline">{{ language.title }}</span>
              </template>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('admin.labels.title')"
                    :label-for="'title.' + language.code"
                  >
                    <b-form-input
                      :id="'title.' + language.code"
                      v-model="item.title[language.code]"
                      :state="errors && errors['title.' + language.code] ? false : null"
                      @input="transliterate($event)"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                      {{ errors['title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <b-row
            class="mb-2"
          >
            <b-col
              cols="12"
              md="4"
            >
              <b-form-radio
                v-model="item.mode"
                name="some-radios"
                value="admin"
              >
                Admin
              </b-form-radio>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-radio
                v-model="item.mode"
                name="some-radios"
                value="client"
              >
                Client
              </b-form-radio>
            </b-col>
          </b-row>

          <b-card
            v-if="item.mode === 'admin'"
            no-body
            class="border mt-1"
          >
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                  icon="LockIcon"
                  size="18"
                />
                <span class="align-middle ml-50">{{ $t('admin.labels.permissions') }}</span>
              </b-card-title>
            </b-card-header>
            <b-table
              striped
              responsive
              class="mb-0"
              :items="item.permissions"
              :fields="permissionsColumns"
            >
              <template #cell(module)="data">
                {{ data.item.module }}
              </template>
              <template #cell(index)="data">
                <b-form-checkbox
                  v-if="data.item.index !== undefined"
                  v-model="data.item.index"
                />
              </template>
              <template #cell(show)="data">
                <b-form-checkbox
                  v-if="data.item.show !== undefined"
                  v-model="data.item.show"
                />
              </template>
              <template #cell(store)="data">
                <b-form-checkbox
                  v-if="data.item.store !== undefined"
                  v-model="data.item.store"
                />
              </template>
              <template #cell(update)="data">
                <b-form-checkbox
                  v-if="data.item.update !== undefined"
                  v-model="data.item.update"
                />
              </template>
              <template #cell(destroy)="data">
                <b-form-checkbox
                  v-if="data.item.destroy !== undefined"
                  v-model="data.item.destroy"
                />
              </template>
            </b-table>
          </b-card>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('admin.buttons.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'roles-index' }"
          >
            {{ $t('admin.buttons.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import CategoryMixin from '@/mixins/CategoryMixin'

export default {
  mixins: [GlobalMixin, CategoryMixin],
  data() {
    return {
      item: null,
      languages: [],
      permissions: [],
      permissionsColumns: [
        { key: 'module', label: this.$t('admin.table.module'), sortable: false },
        { key: 'index', label: this.$t('admin.table.index'), sortable: false },
        { key: 'show', label: this.$t('admin.table.show'), sortable: false },
        { key: 'store', label: this.$t('admin.table.store'), sortable: false },
        { key: 'update', label: this.$t('admin.table.update'), sortable: false },
        { key: 'destroy', label: this.$t('admin.table.destroy'), sortable: false },
      ],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get('/api/permissions')
      .then(response => {
        this.permissions = response.data.data
      })

    const title = {}

    _.each(this.languages, language => {
      title[language.code] = null
    })

    this.item = {
      title,
      name: null,
      mode: 'admin',
      permissions: await this.getPermissions(),
    }
  },
  methods: {
    async onSubmit() {
      await this.$http.post('/api/roles', {
        title: this.item.title,
        name: this.item.name,
        mode: this.item.mode,
        permissions: this.$options.filters.preparePermissions(this.item.permissions),
      })
        .then(() => {
          router.replace({ name: 'roles-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    getPermissions() {
      const permissions = []

      _.each(this.permissions, permission => {
        const splitData = permission.name.split('-')
        let index = _.findIndex(permissions, { module: splitData[0] })

        if (index === -1) {
          index = permissions.length ? permissions.length + 1 : 0
        }

        const permissionData = {
          module: splitData[0],
        }

        permissionData[splitData[1]] = false

        this.$set(permissions, index, _.merge(permissions[index], permissionData))
      })

      return permissions
    },
    transliterate(ev) {
      this.item.name = this.$options.filters.transliterate(ev)
    },
  },
}
</script>
